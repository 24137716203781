<template>
  <!-- 数据看板>人力看板 -->
  <div class="dict">
    <div class="boardTitle">人力看板</div>
    <ul class="top">
      <li>
        <div class="leftIcon">
          <img src="@/assets/img/board/manpower.png" alt="">
        </div>
        <div class="rightDetail">
          <div class="rate">{{humanExecuteRate}}%</div>
          <div class="rateTitle">人力成本执行率
            <el-tooltip placement="top">
              <div slot="content">
                本学年累计(8月26至今)<br/>
                人力成本执行率=人力成本结算额/人力成本目标额
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
        </div>
      </li>
      <li>
        <div class="leftIcon">
          <img src="@/assets/img/board/prepared.png" alt="">
        </div>
        <div class="rightDetail">
          <el-tooltip v-if="executeRate>0" class="item" effect="dark" :content="executeRate>0?'公司整体超编人数：'+executeRate:''" placement="right">
            <div class="rate">超编</div>
          </el-tooltip>
            <div class="rate" v-if="executeRate<=0">合格</div>
          <div class="rateTitle">编制情况
            <el-tooltip placement="top">
              <div slot="content">
                最新更新数据<br/>
                超编人数=实际人数-编制人数<br/>
                超编人数&lt;= 0编制情况为合格，超编人数>0编制情况为超编；
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
        </div>
      </li>
      <li>
        <div class="leftIcon">
          <img src="@/assets/img/board/teacher.png" alt="">
        </div>
        <div class="rightDetail">
          <div class="rate">1:{{teacherRate}}</div>
          <div class="rateTitle">师生比
            <el-tooltip placement="top">
              <div slot="content">
                最新更新数据<br/>
                师生比=（教师总人数+保育总人数）/幼儿总人数
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
        </div>
      </li>
      <li>
        <div class="leftIcon">
          <img src="@/assets/img/board/peoples.png" alt="">
        </div>
        <div class="rightDetail">
          <div class="rate">1:{{studentRate}}</div>
          <div class="rateTitle">员生比
            <el-tooltip placement="top">
              <div slot="content">
                最新更新数据<br/>
                员生比=幼儿园全部教职工人数/幼儿总人数
              </div>
              <span><img src="@/assets/img/wh.png" alt=""></span>
            </el-tooltip>
          </div>
        </div>
      </li>
    </ul>
    <ul class="bottom">
      <li>
        <p class="echartsTitle">总员工数
          <!-- <el-tooltip placement="top">
            <div slot="content">
              本学年累计（9月1日至今）<br/>
              生均利润=总收入/总人数
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip> -->
        </p>
        <div id="echarts1"  v-show="Object.keys(echartData1).length"></div>
        <div class="noEachartData"  v-if="!Object.keys(echartData1).length">暂无数据</div>
      </li>
      <li class="bottomCenter">
        <p class="echartsTitle">员工流失率
          <el-tooltip placement="top">
            <div slot="content">
              本学年累计（9月1日至今）<br/>
              员工流失率=离职人数/（期末人数+离职人数）
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip>
        </p>
        <div id="echarts2" v-show="Object.keys(echartData2).length"></div>
        <div class="noEachartData"  v-if="!Object.keys(echartData2).length">暂无数据</div>
      </li>
      <li>
        <p class="echartsTitle">持证率
          <el-tooltip placement="top">
            <div slot="content">
              最新更新数据<br/>
              教师持证率=教师岗位持证人数/教师岗位总人数；<br/>
              保育持证率=保育岗位持证人数/保育岗总人数；<br/>
              保健持证率=保健岗位持证人数/保健岗总人数；
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip>
        </p>
        <div id="echarts3" v-show="Object.keys(echartData3).length"></div>
        <div class="noEachartData"  v-if="!Object.keys(echartData3).length">暂无数据</div>
      </li>
      <li>
        <p class="echartsTitle">学历分布
          <!-- <el-tooltip placement="top">
            <div slot="content">
              生均利润=总收入/总人数
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip> -->
        </p>
        <div id="echarts4" v-show="Object.keys(echartData4).length"></div>
        <div class="noEachartData"  v-if="!Object.keys(echartData4).length">暂无数据</div>
        <div class="echartsDesc" >
          <div class="itemDesc" v-for="(item,index) in eduDistributeData" :key="index">
            <span :class="'dotColor bgColor'+index"></span>
            <span class="optionTitle">{{item.name}}</span>
            <span class="peopleNum">{{item.value}}人</span>
          </div>
          <!-- <div class="itemDesc">
            <span class="dotColor dazhuan"></span>
            <span class="optionTitle">大专</span>
            <span class="peopleNum">85人</span>
          </div>
          <div class="itemDesc">
            <span class="dotColor shuoshi"></span>
            <span class="optionTitle">硕士及以上</span>
            <span class="peopleNum">85人</span>
          </div>
          <div class="itemDesc">
            <span class="dotColor zhongzhuan"></span>
            <span class="optionTitle">中专及以下</span>
            <span class="peopleNum">85人</span>
          </div> -->
        </div>
      </li>
      <li class="bottomCenter">
        <p class="echartsTitle">年龄结构
          <!-- <el-tooltip placement="top">
            <div slot="content">
              生均利润=总收入/总人数
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip> -->
        </p>
        <div id="echarts5" v-show="Object.keys(echartData5).length"></div>
        <div class="noEachartData"  v-if="!Object.keys(echartData5).length">暂无数据</div>
        <div class="echartsAges">
          <div class="itemDesc" v-for="(item,index) in ageData" :key="index">
            <span :class="'dotColor bgColor'+index"></span>
            <span class="optionTitle">{{item.name}}</span>
            <span class="peopleNum">{{item.value}}人</span>
          </div>
          <div class="itemDesc" v-if="ageData.length == 4"></div>
          <div class="itemDesc" v-if="ageData.length > 4"></div>
          <!-- <div class="itemDesc">
            <span class="dotColor dazhuan"></span>
            <span class="optionTitle">大专</span>
            <span class="peopleNum">85人</span>
          </div>
          <div class="itemDesc">
            <span class="dotColor shuoshi"></span>
            <span class="optionTitle">硕士及以上</span>
            <span class="peopleNum">85人</span>
          </div>
          <div class="itemDesc">
            <span class="dotColor zhongzhuan"></span>
            <span class="optionTitle">中专及以下</span>
            <span class="peopleNum">85人</span>
          </div> -->
        </div>
      </li>
      <li>
        <p class="echartsTitle">人力发展（储备干部、晋升池）
          <!-- <el-tooltip placement="top">
            <div slot="content">
              生均利润=总收入/总人数
            </div>
            <span><img src="@/assets/img/wh.png" alt=""></span>
          </el-tooltip> -->
        </p>
        <div id="echarts6" v-show="Object.keys(echartData6).length"></div>
        <div class="noEachartData"  v-if="!Object.keys(echartData6).length">暂无数据</div>
      </li>
    </ul>
  </div>
</template>

<script>
import LTable from "@/components/tableComponent/index.vue";
import {
  getPermissionButton,
  isHasPermission,
} from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  components: {
    LTable,
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback();
      }
      let valueString = value + "";
      let numberAuth = /[^\d]/g;
      if (numberAuth.test(valueString)) {
        callback(new Error("只支持数字"));
      } else if (valueString.length > 1 && valueString.substr(0, 1) === "0") {
        callback(new Error("只支持数字，首位不能是0"));
      } else {
        callback();
      }
    };
    return {
      permissionButtonList: [], // 权限按钮list
      isUpdate: false,
      isAdd: false,
      isDelete: false,
      ageData:[],
      eduDistributeData:[],
      studentRate:0,
      teacherRate:0,
      humanExecuteRate:0,
      executeRate:0,
      echartData1:{
        name:[]
      },
      echartData2:{
        name:[]
      },
      echartData3:{
        name:[]
      },
      echartData4:{
        name:[]
      },
      echartData5:{
        name:[]
      },
      echartData6:{
        name:[]
      },
    };
  },
  computed: {
    ...mapGetters(["permissions"]),
    ...mapState({
      menuList: (state) => state.common.menuList,
    }),
  },
  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isUpdate = val.indexOf("dict:update") != -1;
          this.isAdd = val.indexOf("dict:save") != -1;
          this.isDelete = val.indexOf("dict:delete") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            // console.log(data, "右侧权限按钮按钮按钮按钮啊");
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },
  created() {
    // this.init();
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      // console.log(data, "右侧权限按钮按钮按钮按钮啊created");
      this.permissionButtonList = data;
    });
    setTimeout(()=>{
      this.initEcharts1();
      this.initEcharts2();
      this.initEcharts3();
      this.initEcharts4();
      this.initEcharts5();
      this.initEcharts6();
    },200)
    // this.initData()
    this.initExecuteRate()
    this.initHumanExecuteRate()
    this.initTeacherRate()
    this.initStudentRate()
    window.addEventListener('resize',()=> {
      let echarts1 = this.$echarts.init(document.getElementById("echarts1"));
      let echarts2 = this.$echarts.init(document.getElementById("echarts2"));
      let echarts3 = this.$echarts.init(document.getElementById("echarts3"));
      let echarts4 = this.$echarts.init(document.getElementById("echarts4"));
      let echarts5 = this.$echarts.init(document.getElementById("echarts5"));
      let echarts6 = this.$echarts.init(document.getElementById("echarts6"));
      echarts1.resize();
      echarts2.resize();
      echarts3.resize();
      echarts4.resize();
      echarts5.resize();
      echarts6.resize();
    });
  },
  methods: {
    getPermissionButton,
    isHasPermission,
    // initData(){
    //   this.$api.getStaffLeaveRate().then(res=>{
    //     if(res.data.code == '0'){
    //       // console.log(res.data.data)
    //       let data = res.data.data
    //     }else{
    //       this.$message.error(res.data.msg)
    //     }
    //   })
    // },
    initTeacherRate(){
      this.$api.getTeacherRate().then(res=>{
        if(res.data.code == '0'){
          // console.log(res.data.data)
          let data = res.data.data
          this.teacherRate = data.rate
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initStudentRate(){
      this.$api.getStudentRate().then(res=>{
        if(res.data.code == '0'){
          // console.log(res.data.data)
          let data = res.data.data
          this.studentRate = data.rate
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
      // studentRate:0,
      // teacherRate:0,
      // humanExecuteRate:0,
      // executeRate:0,
    // initStaffLeaveRate(){
    //   this.$api.getStaffLeaveRate().then(res=>{
    //     if(res.data.code == '0'){
    //       // console.log(res.data.data)
    //       let data = res.data.data
    //       this.teacherRate = data.rate
    //     }else{
    //       this.$message.error(res.data.msg)
    //     }
    //   })
    // },
    initHumanExecuteRate(){
      this.$api.getHumanExecuteRate().then(res=>{
        if(res.data.code == '0'){
          // console.log(res.data.data)
          let data = res.data.data
          this.humanExecuteRate = data.rate
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initExecuteRate(){
      this.$api.getExecuteRate().then(res=>{
        if(res.data.code == '0'){
          // console.log(res.data.data)
          let data = res.data.data
          this.executeRate = res.data.data.realNum - res.data.data.number
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts1(){
      this.$api.getAllStaff().then(res=>{
        if(res.data.code == '0'){
          console.log(res.data.data)
          let data = res.data.data
          this.echartData1 = data
          let echarts1 = this.$echarts.init(document.getElementById("echarts1"));
          // 绘制星期-出险数量对比图图表
          let option1 = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(9, 12, 21, 0.8)",
              borderColor: "rgba(9, 12, 21, 0.8)",
              textStyle: {
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: 'category',
              data: data.post,
              axisTick: {
                show: false  //不显示坐标轴刻度
              },
              axisLine: {
                show: false  //不显示坐标轴轴线
              },
              axisLabel: {
                // rotate:30,
                textStyle:{//改变xy轴上文字的颜色
                  color:"#A8A6B9"
                }
              },
            },
            yAxis: {
              type: 'value',
              axisLabel:{
                textStyle:{
                  color:"#A8A6B9"
                }
              }
            },
            grid: {
              bottom:40,
              // left:50,
            },
            series: [{
              data: data.userNumber,
              type: 'bar',
              barWidth: 20,//柱图宽度
              itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [4, 4, 0, 0],
                  color: '#004DA0'
                },
              },
            }]
          };
          echarts1.setOption(option1);
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts2(){
      this.$api.getStaffLeaveRate({
        type:1
      }).then(res=>{
        if(res.data.code == '0'){
          // console.log(res.data.data)
          let data = res.data.data
          this.echartData2 = data
          let echarts2 = this.$echarts.init(document.getElementById("echarts2"));
          // 绘制星期-出险数量对比图图表
          let option2 = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(9, 12, 21, 0.8)",
              borderColor: "rgba(9, 12, 21, 0.8)",
              textStyle: {
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: 'category',
              data: data.name,
              axisTick: {
                show: false  //不显示坐标轴刻度
              },
              axisLine: {
                show: false  //不显示坐标轴轴线
              },
              axisLabel: {
                rotate:50,
                textStyle:{//改变xy轴上文字的颜色
                  color:"#A8A6B9"
                }
              },
            },
            yAxis: {
              type: 'value',
              axisLabel:{
                textStyle:{
                  color:"#A8A6B9"
                },
                formatter:function (value){
                  return value+'%'
                }
              }
            },
            grid: {
              bottom:70,
              // left:50,
            },
            series: [{
              data: data.rate,
              type: 'bar',
              barWidth: 20,//柱图宽度
              itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [4, 4, 0, 0],
                  color: function(params) {
                    // console.log(params)
                    if(params.data >= 100){
                      return '#E7C69C'
                    }else{
                      return '#004DA0'
                    }
                  }
                },
              },
            }]
          };
          echarts2.setOption(option2);
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts3(){
      this.$api.getCredentialsRate().then(res=>{
        if(res.data.code == '0'){
          console.log(res.data.data)
          let data = res.data.data
          this.echartData3 = data
          let echarts3 = this.$echarts.init(document.getElementById("echarts3"));
          // 绘制星期-出险数量对比图图表
          let option3 = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(9, 12, 21, 0.8)",
              borderColor: "rgba(9, 12, 21, 0.8)",
              textStyle: {
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: 'category',
              data: data.post,
              axisTick: {
                show: false  //不显示坐标轴刻度
              },
              axisLine: {
                show: false  //不显示坐标轴轴线
              },
              axisLabel: {
                // rotate:30,
                textStyle:{//改变xy轴上文字的颜色
                  color:"#A8A6B9"
                }
              },
            },
            yAxis: {
              type: 'value',
              axisLabel:{
                textStyle:{
                  color:"#A8A6B9"
                },
                formatter:function (value){
                  return value+'%'
                }
              }
            },
            grid: {
              bottom:40,
              // left:50,
            },
            series: [{
              data: data.userNumber,
              type: 'bar',
              barWidth: 40,//柱图宽度
              itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [4, 4, 0, 0],
                  color: '#004DA0'
                },
              },
            }]
          };
          echarts3.setOption(option3);
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts4(){
      // 颜色值顺序 本科，大专，硕士及以上，中专及以下
      this.$api.getEduDistribute().then(res=>{
        if(res.data.code == '0'){
          // console.log(res.data.data)
          this.eduDistributeData = res.data.data
          let data = res.data.data
          this.echartData4 = data
          let echarts4 = this.$echarts.init(document.getElementById("echarts4"));
          let option4 = {
            tooltip: {
              trigger: 'item',
              show:false,
            },
            legend: {
              show:false,
              top: '70%',
              left: 'center'
            },
            grid: {
              // top:-20,
              // left:50,
            },
            color: ['#004DA0', '#E7C69C', '#00AB9F','#93252A'],
            series: [
              {
                name: 'Access From',
                type: 'pie',
                radius: ['50%', '68%'],
                center:['50%','40%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    formatter: function(params){
                      let ele = `{a|${params.percent}%} \n {b| ${params.name}}`
                      // '<span>'+params.value+'</span><br><span>'+params.name+'</span>'
                      return ele
                    },
                    rich: {
                      a:{
                        fontSize: 20,
                        textAlign: "center",
                        color: "#000"
                      },
                      b:{
                        fontSize: 12,
                        textAlign: "center",
                        color: "#6C7293"
                      },
                    },
                  },
                  
                },
                labelLine: {
                  show: false
                },
                data:data,
              }
            ]
          };
          echarts4.setOption(option4);
          let dataCount = data.length;
          // 设置默认展示，seriesIndex：系列名，dataIndex：当前索引
          echarts4.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
          // 鼠标进入高亮当前，取消其他高亮
          echarts4.on("mouseover", (e) => {
            console.log(e,'8989')
            echarts4.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: e.dataIndex,
            });
            // 根据当前option中data的length，遍历取消其他高亮
            for(let i =0;i<dataCount;i++){
              if(i != e.dataIndex){
                echarts4.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: i,
              });
              }
            }
          });
          //鼠标移出后显示之前默认高亮的那块，其他取消高亮
          echarts4.on("mouseout", (e) => {
            console.log(e,'你猜')
            if (e.dataIndex !== 0) {
              echarts4.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: e.dataIndex,
              });
            } else {
              echarts4.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });
        
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts5(){
      // 颜色值顺序 本科，大专，硕士及以上，中专及以下
      this.$api.getAgeDistribute().then(res=>{
        if(res.data.code == '0'){
          // console.log(res.data.data)
          this.ageData = res.data.data
          let data = res.data.data
          this.echartData5 = data
          let echarts5 = this.$echarts.init(document.getElementById("echarts5"));
          let option5 = {
            tooltip: {
              trigger: 'item',
              show:false,
            },
            legend: {
              show:false,
              top: '70%',
              left: 'center'
            },
            color: ['#004DA0', '#E7C69C', '#2F5ECB','#00AB9F','#93252A','#003685'],
            series: [
              {
                name: 'Access From',
                type: 'pie',
                radius: ['50%', '68%'],
                center:['50%','40%'],
                avoidLabelOverlap: false,
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    formatter: function(params){
                      let ele = `{a|${params.value}} \n {b| ${params.name}}`
                      // '<span>'+params.value+'</span><br><span>'+params.name+'</span>'
                      return ele
                    },
                    rich: {
                      a:{
                        fontSize: 20,
                        textAlign: "center",
                        color: "#000"
                      },
                      b:{
                        fontSize: 12,
                        textAlign: "center",
                        color: "#6C7293"
                      },
                    },
                  },
                  
                },
                labelLine: {
                  show: false
                },
                data: data,
              }
            ]
          };
          echarts5.setOption(option5);
          
          let dataCount = data.length;
          // 设置默认展示，seriesIndex：系列名，dataIndex：当前索引
          echarts5.dispatchAction({
            type: "highlight",
            seriesIndex: 0,
            dataIndex: 0,
          });
          // 鼠标进入高亮当前，取消其他高亮
          echarts5.on("mouseover", (e) => {
            console.log(e,'8989')
            echarts5.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: e.dataIndex,
            });
            // 根据当前option中data的length，遍历取消其他高亮
            for(let i =0;i<dataCount;i++){
              if(i != e.dataIndex){
                echarts5.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: i,
              });
              }
            }
          });
          //鼠标移出后显示之前默认高亮的那块，其他取消高亮
          echarts5.on("mouseout", (e) => {
            console.log(e,'你猜')
            if (e.dataIndex !== 0) {
              echarts5.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: e.dataIndex,
              });
            } else {
              echarts5.dispatchAction({
                type: "highlight",
                seriesIndex: 0,
                dataIndex: 0,
              });
            }
          });
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
    initEcharts6(){
      this.$api.getHumanDevelop().then(res=>{
        if(res.data.code == '0'){
          let data = res.data.data
          this.echartData6 = data
          let echarts6 = this.$echarts.init(document.getElementById("echarts6"));
          // 绘制星期-出险数量对比图图表
          let option6 = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(9, 12, 21, 0.8)",
              borderColor: "rgba(9, 12, 21, 0.8)",
              textStyle: {
                fontSize: 14,
                textAlign: "center",
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
              },
            },
            xAxis: {
              type: 'category',
              data: data.post,
              axisTick: {
                show: false  //不显示坐标轴刻度
              },
              axisLine: {
                show: false  //不显示坐标轴轴线
              },
              axisLabel: {
                // rotate:30,
                textStyle:{//改变xy轴上文字的颜色
                  color:"#A8A6B9"
                }
              },
            },
            yAxis: {
              type: 'value',
              axisLabel:{
                textStyle:{
                  color:"#A8A6B9"
                }
              }
            },
            grid: {
              bottom:40,
              // left:50,
            },
            series: [{
              data: data.userNumber,
              type: 'bar',
              barWidth: 40,//柱图宽度
              itemStyle: {
                //柱形图圆角，鼠标移上去效果，如果只是一个数字则说明四个参数全部设置为那么多
                normal: {
                  //柱形图圆角，初始化效果
                  barBorderRadius: [4, 4, 0, 0],
                  color: '#004DA0'
                },
              },
            }]
          };
          echarts6.setOption(option6);
        }else{
          this.$message.error(res.data.msg)
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.dict {
  background-color: #F4F4FC;
  min-height: calc(100% - 64px);
  padding: 34px 30px 30px 30px;
  min-width:1200px;
  overflow-x: scroll;
  .tableList {
    height: calc(100% - 62px);
  }
  .boardTitle{
    color: #3D3E4F;
    font-size: 18px;
    font-weight: bold;
  }
  .top{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    li{
      width: 24%;
      // height: 172px;
      background-color: #fff;
      background: #FFFFFF;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      border-radius: 8px;
      display: flex;
      align-items: center;
      padding: 20px;
      box-sizing: border-box;
      .leftIcon{
        width: 52px;
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        img{
          width: 32px;
        }
      }
      &:nth-child(1) .leftIcon{
        background: #93252A;
      }
      &:nth-child(2) .leftIcon{
        background: #00AB9F;
      }
      &:nth-child(3) .leftIcon{
        background: #004DA0;
      }
      &:nth-child(4) .leftIcon{
        background: #E7C69C;
      }
      .rightDetail{
        margin-left: 10px;
      }
      .rate{
        color: #231F32;
        font-size: 24px;
      }
      .rateTitle{
        color: #A7A5B9;
        font-size: 14px;
        margin-top: 6px;
        display: flex;
        align-items: center;
        img{
          width: 15px;
          margin-left: 4px;
        }
      }
    }
  }
  .bottom{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
      margin-top: 30px;
      width: 32%;
      height: 380px;
      background: #FFFFFF;
      box-shadow: 0px 0px 3px rgba(37, 57, 177, 0.04);
      border-radius: 8px;
      &.bottomCenter{
        width: 33%;
      }
      .echartsTitle{
        padding: 28px;
        color: #3D3E4F;
        font-size: 18px;
      }
      #echarts1,#echarts2,#echarts3,#echarts6{
        width: 100%;
        height: calc(100% - 74px);
      }
      #echarts4,#echarts5{
        width: 100%;
        height: calc(100% - 150px);
      }
      .echartsDesc{
        display: flex;
        width: 70%;
        justify-content: center;
        margin: 0 auto;
        flex-wrap: wrap;
        align-items: center;
        .itemDesc{
          width: 50%;
          margin-top: 10px;
          .dotColor{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
          }
          .bgColor0{
            background: #004DA0;
          }
          .bgColor1{
            background: #E7C69C;
          }
          .bgColor2{
            background: #00AB9F;
          }
          .bgColor3{
            background: #93252A;
          }
          .benke{
            background: #004DA0;
          }
          .dazhuan{
            background: #E7C69C;
          }
          .shuoshi{
            background: #00AB9F;
          }
          .zhongzhuan{
            background: #93252A;
          }
          .optionTitle{
            width: 6em;
            font-size: 12px;
            display: inline-block;
            color: #6C7293;
            margin-left: 5px;
          }
          .peopleNum{
            color: #6C7293;
            font-size: 12px;
          }
        }
      }
      .echartsAges{
        display: flex;
        width: 90%;
        justify-content: center;
        margin: 0 auto;
        flex-wrap: wrap;
        align-items: center;
        .itemDesc{
          width: 33.3%;
          margin-top: 10px;
          .dotColor{
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: inline-block;
          }
          .bgColor0{
            background: #004DA0;
          }
          .bgColor1{
            background: #E7C69C;
          }
          .bgColor2{
            background: #2F5ECB;
          }
          .bgColor3{
            background: #00AB9F;
          }
          .bgColor4{
            background: #922529;
          }
          .bgColor5{
            background: #003685;
          }
          .bgColor6{
            background: #004DA0;
          }
          .benke{
          }
          .dazhuan{
            background: #E7C69C;
          }
          .shuoshi{
            background: #00AB9F;
          }
          .zhongzhuan{
            background: #93252A;
          }
          .optionTitle{
            width: 4em;
            font-size: 12px;
            display: inline-block;
            color: #6C7293;
            margin-left: 5px;
          }
          .peopleNum{
            color: #6C7293;
            font-size: 12px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1800px) and (max-width: 1920px) {
  .dict {
    .boardTitle{
      color: #3D3E4F;
      font-size: 18px;
      font-weight: bold;
    }
    .top{
      margin-top: 30px;
      li{
        padding: 40px;
        .leftIcon{
          width: 92px;
          height: 92px;
        }
        .rightDetail{
          margin-left: 10px;
        }
        .rate{
          font-size: 30px;
        }
        .rateTitle{
          font-size: 20px;
          margin-top: 10px;
        }
      }
    }
  }
}
.noEachartData{
  height: calc(100% - 74px);
  width: 100%;
  // height: 100%;
  // text-align: center;
  color: #999;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  /deep/div{
    display: none;
  }
}
</style>
